import React from "react"
import { useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "ricardo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <div className="max-w-2xl mx-auto">
        <div>
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            className=" w-48 mx-auto rounded-full round mb-8"
            alt="Ricardo Macario"
          />
          <h1>Hi, I'm Ricardo</h1>
          <p>
            I work for <a href="https://shopify.com">Shopify</a> as a Frontend
            Developer. I'm also the creator of{" "}
            <a href="https://appdelante.com">Appdelante</a>, an online school to
            learn software development in spanish.
          </p>
          <p>
            This site will host all my writing and ideas. My top interests are:
            self development, marketing, nutrition, investing, meditation, indie
            hacking, improv, habit formation and fitness.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
